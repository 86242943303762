
import { Requisicao } from '@/core/models/almoxarifado';
import { PageBase } from '@/core/models/shared';
import { RequisicaoService } from '@/core/services/almoxarifado';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { Component } from 'vue-property-decorator';


@Component
export default class RelatorioRequisicao extends PageBase { 

    service = new RequisicaoService();
    item: Requisicao = new Requisicao();
    overlay: boolean = false;
    filter:any = new Requisicao();

    empresas:any  = [];
    empreendimentos:any = [];

    mounted() {
        const filter = this.$route.query;
        this.filter = filter;
        this.overlay = true;
        this.service.ListarItensComFiltro(filter, 'Requisicao, Etapa, Produto')
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });

            new EmpresaService().ListarTudo().then(
                res => this.empresas = res.data.items
            )

            new EmpreendimentoService().ListarTudo().then(
                res => this.empreendimentos = res.data.items
            )
    }
}
